import React from "react";

export default function AccessDenied() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="permission-wrapper">
          <div className="">
            <svg className="mb16" xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
              <title>711B81BD-4085-43E1-A338-EAAEA2529C37@1x</title>
              <g id="Other-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Access-Denied" transform="translate(-761.000000, -350.000000)" fill="#BDC0C4">
                  <g id="Access-Denied-1" transform="translate(570.000000, 350.000000)">
                    <g id="icon/24x24/ban" transform="translate(191.000000, 0.000000)">
                      <path d="M32,0 C14.3268387,0 0,14.3268387 0,32 C0,49.6731613 14.3268387,64 32,64 C49.6731613,64 64,49.6731613 64,32 C64,14.3268387 49.6731613,0 32,0 Z M50.247871,13.752129 C59.6899355,23.1940645 60.1898065,37.92 52.3077419,47.9282581 L16.0717419,11.6922581 C26.0830968,3.80774194 40.8083871,4.31251613 50.247871,13.752129 Z M13.752129,50.247871 C4.31006452,40.8059355 3.81019355,26.08 11.6922581,16.0717419 L47.9282581,52.3077419 C37.9169032,60.1922581 23.1916129,59.6874839 13.752129,50.247871 Z" id="ban" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <h4 className="astm-type-heading--h4">Access Denied</h4>
            <p>You do not have permission to view this page.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
