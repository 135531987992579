import React, { useEffect, useReducer, useRef } from "react";
import { generateUUID } from "../../helpers/util-common";

interface actionObject {
  type: string;
}

interface initialStateForAccordion {
  collapse: boolean;
}

interface Props {
  show: boolean;
  title: string;
  renderChildInfo: React.ReactNode;
  closeDocumentInfoSection: any;
}

const initialStateForAccordion = {
  collapse: false,
};

// eslint-disable-next-line no-use-before-define
const reducer = (state: initialStateForAccordion = initialStateForAccordion, action: actionObject) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "collapse":
      return {
        collapse: !state.collapse,
      };

    case "show":
      return {
        collapse: true,
      };
  }
};

const Accordion = ({ show = false, title, renderChildInfo, closeDocumentInfoSection }: Props) => {
  const accordionBodyRef = useRef(null);

  const [stateInformation, dispatch] = useReducer(reducer, {
    collapse: show,
  });

  const collapse = stateInformation?.collapse;

  const randomId = useRef(generateUUID());

  useEffect(() => {
    if (show) dispatch({ type: "show" });
  }, [show]);

  return (
    <>
      <div className="accordion-item card astm-card">
        <h2 className="accordion-header card-header position-relative" id={`heading-${randomId.current}`} onClick={() => dispatch({ type: "collapse" })} data-testid="collapse">
          <span>
            <i className={`astm-icon fas fa-caret-down text-center mr8 ${!collapse ? "astm-toggle-arrow" : ""}`}></i>
            {/* <i className="astm-icon fas fa-caret-up text-center mr8"></i> */}
            {title}
          </span>
        </h2>
        {title === "Document Information" && (
          <div className="right-action-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="4px" height="16px" viewBox="0 0 4 16" version="1.1">
              <g id="Documents---Agendas" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="23_COW-Wireframe---Agendas" transform="translate(-1153.000000, -274.000000)" fill="#232f3a" fillRule="nonzero">
                  <g id="Info-Panel" transform="translate(855.000000, 261.000000)">
                    <g id="Overflow" transform="translate(286.000000, 7.000000)">
                      <g id="ellipsis-v" transform="translate(12.000000, 6.000000)">
                        <path d="M2,6.08 C3.10416667,6.08 4,6.94 4,8 C4,9.06 3.10416667,9.92 2,9.92 C0.895833333,9.92 0,9.06 0,8 C0,6.94 0.895833333,6.08 2,6.08 Z M0,1.92 C0,2.98 0.895833333,3.84 2,3.84 C3.10416667,3.84 4,2.98 4,1.92 C4,0.86 3.10416667,0 2,0 C0.895833333,0 0,0.86 0,1.92 Z M0,14.08 C0,15.14 0.895833333,16 2,16 C3.10416667,16 4,15.14 4,14.08 C4,13.02 3.10416667,12.16 2,12.16 C0.895833333,12.16 0,13.02 0,14.08 Z" id="Shape" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <i className="astm-icon far fa-times" data-testid="close-btn" onClick={closeDocumentInfoSection}></i>
          </div>
        )}
        <div
          id={`collapse-${randomId.current}`}
          aria-labelledby={`heading-${randomId.current}`}
          className={`accordion-collapse ${collapse ? "accordion-toggle-collapse" : ""}`}
          style={
            collapse
              ? {
                  height: "auto",
                  transition: "height 0.2s ease",
                  overflow: "hidden",
                }
              : {
                  height: 0,
                  transition: "height 0.2s ease",
                  overflow: "hidden",
                }
          }
        >
          <div className="" ref={accordionBodyRef}>
            <ul className="list-group astm-list-group">{renderChildInfo}</ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
