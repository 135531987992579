import React, { Suspense, useEffect, useContext } from "react";
import { Routes, Route, Navigate, Link } from "react-router-dom";
import CustomLoader from "../shared-components/Loader";
import { customeRoutes } from "./lazy-load";
import { LEFT_NAVIGATION_PAGE_URL } from "../models/navigation.models";
import Permission from "../shared-components/permission/Permission";

interface Props {
  defaultUrl?: any;
  isUserAuthorized: any;
  isLoginByAzureAD?: any;
  userDetail: any;
  history: any;
}
const Navigation: React.FC<Props> = (props: Props) => {
  const { history } = props;
  return (
    <div>
      <Suspense fallback={<CustomLoader />}>
        <Routes>
          <Route path={LEFT_NAVIGATION_PAGE_URL.DASHBOARD} element={<customeRoutes.DashBoard />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD} element={<customeRoutes.DocumentsUpload />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.BALLOT} element={<customeRoutes.Ballot />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.ALL_DOCUMENTS} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.AGENDAS} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.MINUTES} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.COMMITTES} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT} element={<customeRoutes.AllDocuments />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD} element={<customeRoutes.LitigationHold />} />
          <Route path={LEFT_NAVIGATION_PAGE_URL.BALLOT} element={NotFound()} />
        </Routes>
      </Suspense>
    </div>
  );
};

function NotFound() {
  return (
    <>
      {/* <Permission /> */}
      {/* <AccessDenied />
      <UnderConstruction /> */}
    </>
  );
}

export default Navigation;
