// import { createStore, applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import rootReducer from './reducer/root-reducer';
import { i18nActions } from 'redux-react-i18n';
import { translations } from '../translations';

const dictionaries = translations;

const languages = [
  {
    code: 'en-US',
    name: 'English (USA)',
  },
];

export type AppState = ReturnType<typeof rootReducer>;
export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

store.dispatch(i18nActions.setDictionaries(dictionaries));
store.dispatch(i18nActions.setLanguages(languages));
store.dispatch(i18nActions.setCurrentLanguage('en-US'));

