import * as type from '../../action-type';

interface DocumentCategoryData {
    documentCategoryID: number;
    name: string;
    status: number,
    metaDataTemplete: string
}

interface InitialState {
    isDocumentInfo: boolean,
    allDocumentsClickedId: string,
    activeDocumentInfoDataId: number,
    displayFileName: string,
    hasUpdateAccess: boolean
}

const initialState: InitialState = {
    isDocumentInfo: false,
    allDocumentsClickedId: '',
    activeDocumentInfoDataId: 0,
    displayFileName: '',
    hasUpdateAccess: false
};

const allDocumentsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.CLICK_DOCUMENT_INFO_ICON:
            return {
                ...state,
                isDocumentInfo: action.data.isDocumentInfo,
                allDocumentsClickedId: action.data.documentId,
                displayFileName: action.data.displayFileName,
                hasUpdateAccess: action.data.hasAccess,
                currentDocumentChangedStatus: action.data.currentDocumentChangedStatus,
            }

        case type.UPDATE_DOCUMENT_LIST:
            return {
                ...state,
                activeDocumentInfoDataId: action.activeDocumentInfoDataId
            }

        default:
            return state;
    }
}

export default allDocumentsReducer;