import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { flagFeature } from "../common.actions";

interface IContextProps {
  isInitializing: boolean;
  FlagFeaturesData: object;
}
const InitialValue = {
  isInitializing: false,
  FlagFeaturesData: {},
};
type FeatureFlagProviderProps = {
  children: React.ReactNode;
};
export const FeatureFlag = createContext<IContextProps>(InitialValue);
export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const [featureFlag, setFeatureFlag] = useState(InitialValue);
  const [loadChild, setLoadChild] = useState(false);
  const dispatch = useDispatch();

  const updateFlag = (data: any) => {
    setFeatureFlag(data);
  };
  useEffect(() => {
    flagFeature((response: any) => {
      setLoadChild(true);
      updateFlag({ isInitializing: response.data.Status, FlagFeaturesData: response.data.Result });
    }, dispatch);
  }, [true]);
  return <FeatureFlag.Provider value={featureFlag}>{loadChild && children} </FeatureFlag.Provider>;
};
