import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = (props: any) => {
  return (
    <OverlayTrigger
      key={props.title}
      placement={props.placement}
      overlay={
        <Tooltip className={props.className} id={props.title}>
          {props.title}
        </Tooltip>
      }
    >
      {props.HTMLElement}
    </OverlayTrigger>
  );
};
export default CustomTooltip;
