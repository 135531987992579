import React from "react";

export default function InvalidUrl() {
  return (
    <div className="row" data-testid="invalidURL-id">
      <div className="col-md-12">
        <div className="permission-wrapper">
          <div className="mb24">
            <svg xmlns="http://www.w3.org/2000/svg" data-testid="invalid-icon" width="64px" height="58px" viewBox="0 0 64 58" version="1.1">
              <title>0A92BA55-DDFF-4284-B608-E69BDAB541BF@1x</title>
              <g id="Other-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Do-not-have-permission-to-access" transform="translate(-761.000000, -353.000000)" fill="#BDC0C4">
                  <g id="Access-Denied-2" transform="translate(570.000000, 350.000000)">
                    <g id="icon/24x24/exclamation-triangle" transform="translate(190.999983, 3.199999)">
                      <path
                        d="M27.6385727,22.7450005 L28.3705727,35.189445 C28.4120171,35.8942228 28.9956838,36.444445 29.7015727,36.444445 L34.2982393,36.444445 C35.0041816,36.444445 35.5877652,35.8941679 35.6292393,35.189445 L36.3612393,22.7450005 C36.4062393,21.9791117 35.7973504,21.3333339 35.0302393,21.3333339 L28.9694616,21.3333339 C28.2024616,21.3333339 27.5935727,21.9791117 27.6385727,22.7450005 L27.6385727,22.7450005 Z M36.6666838,42.6666672 C36.6666838,45.2440005 34.5773504,47.3333339 32.0000171,47.3333339 C29.4226838,47.3333339 27.3333504,45.2440005 27.3333504,42.6666672 C27.3333504,40.0893339 29.4226838,38.0000005 32.0000171,38.0000005 C34.5773504,38.0000005 36.6666838,40.0893339 36.6666838,42.6666672 Z M36.6196838,2.66500055 C34.5715727,-0.885110563 29.4322393,-0.891555008 27.3803504,2.66500055 L0.719683781,48.8903339 C-1.32720511,52.438445 1.23535045,56.8888894 5.33935045,56.8888894 L58.6600171,56.8888894 C62.7561282,56.8888894 65.3305727,52.4452228 63.2796838,48.8903339 L36.6196838,2.66500055 Z M5.91012823,50.5557783 L31.4225727,6.33422277 C31.6791282,5.8895561 32.320906,5.8895561 32.5774616,6.33422277 L58.089906,50.5556672 C58.3463504,51.0001117 58.0255727,51.555445 57.5124616,51.555445 L6.48757267,51.555445 C5.97457267,51.555445 5.65379489,51.0002228 5.91012823,50.5557783 L5.91012823,50.5557783 Z"
                        id="exclamation-triangle"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            {/* <h4 className="astm-type-heading--h4">Access Denied</h4> */}
          </div>
          <p>The page you are looking for either does not exist or temporarily unavailable</p>
        </div>
      </div>
    </div>
  );
}
