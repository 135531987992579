import React, { useState } from "react";
import { LEFT_NAVIGATION_PAGE_URL, PAGE_TYPE, USER_PRIVILEGE } from "../models/navigation.models";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import commonActions, { StorCurrentLocation } from "../common.actions";
import { checkUserPermissionOnPage } from "../helpers/util-common";

function Breadcrumb() {
  const dispatch = useDispatch();
  const showballotFolder = (flag: boolean) => {
    commonActions.showHideCreatBallotFolderModelAction(dispatch, flag);
  };
  const props = useSelector((state: any) => {
    return {
      showCreateBallotFolder: state.commonReducer.showCreateBallotFolderModel,
      userPermission: state.commonReducer.userPermission,
      ballotFlag: state.commonReducer.ballotFlag,
    };
  });

  const storyCurrentLocation = (location: string) => {
    StorCurrentLocation(dispatch, location);
  };

  const [showCreateBollotFolder, setShowCreateBallotFolder] = useState(false);

  const handlerCreateBallotFolder = (): void => {
    let status = !props.showCreateBallotFolder;
    setShowCreateBallotFolder(status);
    showballotFolder(status);
  };

  const addConditionBreadcrumb = (): any => {
    const pathName = window.location.pathname;
    switch (pathName) {
      case LEFT_NAVIGATION_PAGE_URL.DASHBOARD:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.DASHBOARD} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.DASHBOARD)}>
                Dashboard
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD)}>
                Upload
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.BALLOT:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            {props.ballotFlag === 1 ? (
              <li className="breadcrumb-item" aria-label="breadcrumb" aria-current="page">
                <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.BALLOT} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT)}>
                  Ballot Folders
                </Link>
              </li>
            ) : (
              <>
                <li className="breadcrumb-item" aria-label="breadcrumb" aria-current="page">
                  Ballot Folders
                </li>

                <li className="breadcrumb-item" aria-label="breadcrumb" aria-current="page">
                  <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.BALLOT} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT)}>
                    Ballot Detail Page
                  </Link>
                </li>
              </>
            )}
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD)}>
                Litigation Hold
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.ALL_DOCUMENTS:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              All Documents
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.AGENDAS:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              All Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.AGENDAS} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.AGENDAS)}>
                Agendas
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.MINUTES:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              All Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.MINUTES} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.MINUTES)}>
                Minutes
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.COMMITTES:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              All Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.COMMITTES} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.COMMITTES)}>
                Committee Documents
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              All Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE)}>
                Ballot Queue
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            {props.ballotFlag === 1 ? (
              <>
                <li className="breadcrumb-item" aria-label="breadcrumb">
                  All Documents
                </li>
                <li className="breadcrumb-item" aria-label="breadcrumb" aria-current="page">
                  <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS)}>
                    Ballot Items
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="breadcrumb-item" aria-label="breadcrumb">
                  Ballot Folders
                </li>
                <li className="breadcrumb-item" aria-label="breadcrumb" aria-current="page">
                  <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS)}>
                    Ballot Detail Page
                  </Link>
                </li>
              </>
            )}
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              All Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.VOTE_ATTACHMENTS)}>
                Vote Attachments
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              All Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.CLOSING_REPORTS)}>
                Closing Reports
              </Link>
            </li>
          </>
        );
      case LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              Documents
            </li>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              All Documents
            </li>
            <li className="breadcrumb-item active" aria-label="breadcrumb" aria-current="page">
              <Link target="_self" to={LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT} onClick={() => storyCurrentLocation(LEFT_NAVIGATION_PAGE_URL.COLLABORATION_AREA_DRAFT)}>
                Collaboration Area Draft
              </Link>
            </li>
          </>
        );
      default:
        return (
          <>
            <li className="breadcrumb-item" aria-label="breadcrumb">
              <a href="#">Home</a>
            </li>
            <li className="breadcrumb-item" aria-label="breadcrumb" aria-current="page">
              <a href="#">Documents</a>
            </li>
          </>
        );
    }
  };

  return (
    <div className="breadcrumb-wrapper">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb astm-breadcrumb">{addConditionBreadcrumb()}</ol>
        </nav>

        {window.location.pathname == LEFT_NAVIGATION_PAGE_URL.BALLOT ? (
          props.ballotFlag === 1 ? (
            <div className="sub-title-wrap">
              <h1 className="astm-type-heading--h1">Ballot Folders</h1>
              {checkUserPermissionOnPage(props.userPermission, PAGE_TYPE.BALLOT_QUEUE, USER_PRIVILEGE.UPDATE) && (
                <button type="button" className="btn astm-btn btn-secondary" data-testid="add-ballot-folder-btn" onClick={() => handlerCreateBallotFolder()}>
                  <i className="astm-icon far fa-plus"></i> Add ballot folder
                </button>
              )}
            </div>
          ) : (
            <div className="sub-title-wrap">
              <h1 className="astm-type-heading--h1">Ballot Detail Page</h1>
            </div>
          )
        ) : window.location.pathname == LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD ? (
          <div className="sub-title-wrap">
            <h1 className="astm-type-heading--h1">Upload</h1>
          </div>
        ) : window.location.pathname == LEFT_NAVIGATION_PAGE_URL.DASHBOARD ? (
          <div className="sub-title-wrap">
            <h1 className="astm-type-heading--h1">Dashboard</h1>
          </div>
        ) : window.location.pathname == LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD ? (
          <div className="sub-title-wrap">
            <h1 className="astm-type-heading--h1">Litigation Hold</h1>
          </div>
        ) : (
          <h1 className="astm-type-heading--h1">{props.ballotFlag === 2 ? "Ballot Detail Page" : "All Documents"}</h1>
        )}

        <div className="solid-border mt16 mb-0"></div>
      </div>
    </div>
  );
}

export default Breadcrumb;
