const en = {
  // Access related messages 1001 to 1100
  "1002": { type: "error", text: "You are not able to login due to invalid token." },
  "1003": { type: "error", text: "Token could not be verified from Redis client." },
  "1004": { type: "error", text: "Your session has expired. Please logout, then login again." },
  "1005": { type: "error", text: "Your session has expired. Please logout, then login again." },
  "1006": { type: "error", text: "Azure Session could not be verified from server, please try again" },
  "1010": { type: "error", text: "User name or password missing." },
  "1011": { type: "error", text: "Error occurred during login. Please try again." },
  "1014": { type: "error", text: "Access denied." },
  // Upload Document Message
  "2001": { type: "error", text: "Committee is required." },
  "2002": { type: "error", text: "Meeting Date is required." },
  "2003": { type: "error", text: "Adding a file is required." },
  "2004": { type: "success", text: "Agenda @filename successfully uploaded." },
  "2005": { type: "danger", text: "An Agenda document already exists with this file name." },
  "2006": { type: "warning", text: "This agenda document is already uploaded for the selected committee and meeting date." },
  "2007": { type: "success", text: "Minute @filename successfully uploaded." },
  "2008": { type: "danger", text: "A Minutes document already exists with this file name." },
  "2009": { type: "warning", text: "This minutes document is already uploaded for the selected committee and meeting date." },
  "2010": { type: "danger", text: "Fail to delete the Document(s)." },
  "2011": { type: "success", text: "Document(s) successfully deleted." },
  "2012": { type: "warning", text: "This display name already exists for selected Committee." },
  "2013": { type: "danger", text: "A Committee document already exists with this file name." },
  "2014": { type: "success", text: "Committee document @filename successfully uploaded." },
  "2015": { type: "success", text: "Display Name is required." },
  "2016": { type: "success", text: "Document(s) successfully replaced." },
  "2017": { type: "danger", text: "Fail to replace the Document '@filename'." },
  "2018": { type: "error", text: "Letter Ballot Number is required." },
  "2019": { type: "error", text: "Item Number is required." },
  "2020": { type: "error", text: "Account Number is required." },
  "2021": { type: "error", text: "Invalid item number." },
  "2022": { type: "error", text: "Invalid Account Number." },
  "2023": { type: "success", text: "Vote attachment @filename successfully uploaded." },
  "2024": { type: "danger", text: "A Vote attachment document already exists with this file name." },
  "2025": { type: "error", text: "Standard Designation is required." },
  "2026": { type: "error", text: "Invalid Standard Designation." },
  "2027": { type: "error", text: "Ballot Action is required." },
  "2028": { type: "error", text: "Ballot Level is required." },
  "2029": { type: "error", text: "Work Item Number is required." },
  "2030": { type: "success", text: "Ballot Queue @filename successfully uploaded." },
  "2031": { type: "error", text: "Sequence Number is required." },
  "2032": { type: "danger", text: "A document already exists with this file name in this ballot folder." },
  "2033": { type: "success", text: "Ballot Item @filename successfully uploaded." },
  "2034": { type: "success", text: "Terms document @filename successfully uploaded." },
  "2035": { type: "danger", text: "A Terms document already exists in this ballot folder." },
  "2036": { type: "danger", text: "Item number not in order, next available item number assigned." },
  "2037": { type: "danger", text: "An item cannot be added to a published ballot." },
  "2038": { type: "danger", text: "An item cannot be added to a closed ballot." },
  "2039": { type: "danger", text: "Error occurred while checking Ballot Folder Status ." },
  "2040": { type: "success", text: "@noOfDocuments agenda documents successfully uploaded:" },
  "2041": { type: "success", text: "@noOfDocuments minutes documents successfully uploaded:" },
  "2042": { type: "danger", text: "An Agenda document(s) already exists with this file name. Please remove the following file(s) to proceed." },
  "2043": { type: "danger", text: "A Minutes document(s) already exists with this file name. Please remove the following file(s) to proceed." },
  "2044": { type: "danger", text: "@noOfDocuments agenda documents failed to upload." },
  "2045": { type: "danger", text: "@noOfDocuments minutes documents failed to upload." },
  "2046": { type: "error", text: "Maximum file upload limit: 50 files." },

  // Closing report
  "1101": { type: "error", text: "Error occurred while listing Closing report." },
  // Vote attachement report
  "1201": { type: "error", text: "Error occurred while listing Voteattachment report" },
  "1202": { type: "danger", text: "Error occurred while listing Letter Ballot" },
  // Ballot Folder List
  "1300": { type: "danger", text: "Error occurred while listing Ballot Folder." },
  "1301": { type: "danger", text: "Ballot folder already exists" },
  "1302": { type: "success", text: "Ballot folder @name successfully added." },
  "1303": { type: "danger", text: "Error occurred while creating new Ballot Folder." },
  "1304": { type: "error", text: "Committee  is required" },
  "1305": { type: "error", text: "Sequence Number is required" },
  "1306": { type: "success", text: "@ballotNumber successfully published." },
  "1307": { type: "danger", text: "Error occurred while publishing Ballot Folder." },
  "1308": { type: "success", text: "@ballotNumber successfully republished." },
  "1309": { type: "danger", text: "Error occurred while marking Ready to publish ." },
  "1310": { type: "danger", text: "Error occurred while getting ballot folder information ." },
  "1311": { type: "success", text: "@ballotNumber successfully marked Ready to publish." },
  "1312": { type: "danger", text: "Error occurred while renaming Ballot item(s)." },
  "1313": { type: "success", text: "Ballot item(s) successfully renamed." },
  "1314": { type: "danger", text: "Please use the correct naming convention - @ballotFolderName.zip." },
  "1315": { type: "danger", text: "Error occurred while uploading ballot folder zip file." },
  "1316": { type: "success", text: "@filename has been uploaded successfully." },

  // Ballot Item Sequence
  "1400": { type: "danger", text: "Error occurred while updating Resequence Ballot Item." },

  //Litigation Hold
  "1500": { type: "danger", text: "Adding a Committee is required." },
  "1501": { type: "danger", text: "A litigation hold has already been placed on the selected main committee." },
  "1502": { type: "danger", text: "Please select the start date." },
  "1503": { type: "danger", text: "Please select the end date." },
  "1504": { type: "danger", text: "Please select the from date." },
  "1505": { type: "danger", text: "Please select the to date." },
  "1506": { type: "danger", text: "If a subcommittee is selected, it will automatically include all related sections within that subcommittee. However, if a section is selected, it will only include that section." },
  "1507": { type: "danger", text: "The 'All time' check box considers all uploaded documents as the document date range. However, you can choose specific dates for the uploaded document date range." },
  "1508": { type: "success", text: "The litigation hold has been applied successfully." },
  "1509": { type: "danger", text: "Error occurred while applying litigation hold." },
  "1510": { type: "danger", text: "Error occoured while getting litigation hold list." },
  "1511": { type: "danger", text: "The start date cannot be after the end date." },
  "1512": { type: "danger", text: "The end date cannot be before the start date." },
  "1513": { type: "danger", text: "The from date cannot be after the to date." },
  "1514": { type: "danger", text: "The to date cannot be before the from date." },
  "1515": { type: "danger", text: "Error occurred while getting litigation hold status." },
  "1516": { type: "danger", text: "Error occurred while deleting the litigation hold." },
  "1517": { type: "success", text: "The litigation hold has been removed successfully for Committee @commitee." },
  "1518": { type: "danger", text: "Adding Subcommittees/Sections is required." },
  "1519": { type: "success", text: "The litigation hold has been updated successfully." },
  // Committee Doc Sequence
  "1600": { type: "danger", text: "Error occurred while Resequence Committee document." },
  //Collaboration Area Draft
  "1700": { type: "danger", text: "Error occurred while listing Collaboration Area Draft." },
};

export default en;
