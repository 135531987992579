import lodash from "lodash";
import { AppActions } from "../../action";
import { SET_MESSAGE, SET_LOADER, SHOW_ERROR_POPUP, SHOW_ALERT_POPUP, SET_MINI_LOADER, SHOW_NOT_FOUND_PAGE, HIDE_NOT_FOUND_PAGE, ALLOW_PERMISSION_FOR_PAGE, IS_SAFARI_AUTOFILL_VISISBLE, STORE_CURRENT_LOCATION, GET_DOCUMENT_STATUS_BY_CATEGORY, SHOW_CREATE_BALLOT_FOLDER, SHOW_SUCCESS_MESSAGE, SET_BALLOT_FLAG, SHOW_DISMISSIBLE_MESSAGE, SHOW_HIDE_BALLOT_MANUAL_PUBLISHSH, MANUAL_ZIP_STATUS_INFO, BALLOT_FIXED_HEADER, SET_LITIGATION_MODAL } from "../../action-type";
import { allowPermissionForAuth, filterPermissionUrl, getModulesAdditionalPrivilege } from "../../helpers/util-common";

import { PAGE_CONFIG } from "../../models/navigation.models";

const initialState = {
  code: null,
  loading: false,
  miniLoader: false,
  showToastMessage: false,
  showSuccessToastMesage: false,
  showDismissibleToastMessage: false,
  showErrorPopup: false,
  errorMessages: [],
  isShowConfirmationPopup: false,
  currentRequest: "",
  statusList: [], // Moved this list in common as it is master data for renewal task module and will not change
  showAlertPopup: false,
  alertMessage: "",
  showNotFoundPage: false,
  userPermission: null,
  PAGE_CONFIG: PAGE_CONFIG,
  appUserList: [],
  isSafariAutoFillInfoVisible: false,
  isDocumentInfo: false,
  message: "",
  dismissibleMessage: "",
  documentStatusList: [],
  showCreateBallotFolderModel: false,
  ballotFlag: 1,
  isEnableBallotManualPublish: false,
  ballotManualZipInfo: { ballotFolderId: 0, ballotInfo: {} },
  ballotFixedHeader: false,
  litigationModal: false,
  uniqueRequestId: ''
};
type actionsType = AppActions;

// Validate All Permission [GET,PUT,DELETE,POST]
export const validateAllPermission = (request: any): any => {
  let pagePermission: any = {};
  let selectedUrl: any = lodash(request).get("Input") && lodash(request).get("Input");
  let groupUrl = lodash(request).get("groupInput") && lodash(request).get("groupInput");
  let additionalPermissionUrl = getModulesAdditionalPrivilege(selectedUrl);
  if (groupUrl && groupUrl.length > 0) {
    let groupFilter = groupUrl.filter((res: any) => res.componentElementOnPage.indexOf(selectedUrl.uniqueKey) > -1);
    if (groupFilter && groupFilter.length === 0 && additionalPermissionUrl && additionalPermissionUrl.length > 0) {
      // Push additional & current page If Component Element on Page get Empty
      groupFilter.push(selectedUrl, ...additionalPermissionUrl);
    }
    if (groupFilter && groupFilter.length > 0) {
      // Push additional & current page Url.
      groupFilter.push(selectedUrl, ...additionalPermissionUrl);
      groupFilter.map((modules: any) => {
        let isPageAuthorized = filterPermissionUrl(modules, modules.oslMethod);
        let isAllow = allowPermissionForAuth(isPageAuthorized);
        if (isAllow) {
          pagePermission[modules.uniqueKey] = true;
        }
      });
    }
  }
  return pagePermission;
};
const getCurrentModule = (currentModuleData: any) => {
  if (currentModuleData && currentModuleData.Input) {
    return currentModuleData.Input.title;
  }

  return "";
};

const reducerCommon = (state: any = initialState, action: actionsType): any => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        code: action.code,
        showToastMessage: action.flag,
        message: action.message,
        uniqueRequestId: action.uniqueRequestId
      };
    case SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        code: action.code,
        showSuccessToastMesage: action.flag,
        message: action.message,
      };
    case SHOW_DISMISSIBLE_MESSAGE:
      return {
        ...state,
        showDismissibleToastMessage: action.flag,
        dismissibleMessage: action.dismissibleMessage,
      };

    case SET_LOADER:
      return {
        ...state,
        loading: action.flag,
      };

    case SHOW_ERROR_POPUP:
      return {
        ...state,
        showErrorPopup: action.flag,
        errorMessages: action.data,
      };
    case SHOW_ALERT_POPUP:
      return {
        ...state,
        showAlertPopup: action.flag,
        alertMessage: action.data,
      };

    case SET_MINI_LOADER:
      return {
        ...state,
        miniLoader: action.flag,
      };

    case SHOW_NOT_FOUND_PAGE:
      return {
        ...state,
        showNotFoundPage: true,
      };
    case HIDE_NOT_FOUND_PAGE:
      return {
        ...state,
        showNotFoundPage: false,
      };
    case ALLOW_PERMISSION_FOR_PAGE:
      return {
        ...state,
        userPermission: validateAllPermission(action),
        PAGE_CONFIG: { ...PAGE_CONFIG },
        currentModuleTitle: getCurrentModule(action),
      };
    case IS_SAFARI_AUTOFILL_VISISBLE:
      return {
        ...state,
        isSafariAutoFillInfoVisible: action.flag,
      };
    case STORE_CURRENT_LOCATION:
      return {
        ...state,
        currentRequest: action.data,
      };
    case GET_DOCUMENT_STATUS_BY_CATEGORY:
      return {
        ...state,
        documentStatusByCategory: action.data,
      };
    case SHOW_CREATE_BALLOT_FOLDER:
      return {
        ...state,
        showCreateBallotFolderModel: action.flag,
      };
    case SET_BALLOT_FLAG:
      return {
        ...state,
        ballotFlag: action.ballotFlag,
      };
    case SHOW_HIDE_BALLOT_MANUAL_PUBLISHSH:
      return {
        ...state,
        isEnableBallotManualPublish: action.flag,
      };
    case MANUAL_ZIP_STATUS_INFO:
      return {
        ...state,
        ballotManualZipInfo: action?.ballotManualZipInfo,
      };
    case BALLOT_FIXED_HEADER:
      return {
        ...state,
        ballotFixedHeader: action?.data,
      };
    case SET_LITIGATION_MODAL:
      return {
        ...state,
        litigationModal: action.litigationModal,
      };
    default:
      return state;
  }
};

export default reducerCommon;
