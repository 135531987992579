// @flow

import { AppActions } from '../../action';
import { LOGIN,AZURE_LOGIN,AZURE_REFRESH_TOKEN} from '../../action-type';

const initialState = {
	loginCredential: null,
	azureCredentials: null,
	azureRefereshTokenCredentials: null
};
type actionsType = AppActions;
const loginReducer = (state:any = initialState, action: actionsType):any  => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				login: action.payload
			};
		case AZURE_LOGIN:
			return {
				...state,
				azureCredentials: action.payload
			};

		case AZURE_REFRESH_TOKEN:
			return {
				...state,
				azureRefereshTokenCredentials: action.payload
			};
		default:
			return state;
	}
};

export default loginReducer;
