import React, { useEffect, useState } from 'react';
function CustomLoader() {
    return (
        <div className='CustomLoader'>
            <div className="astm-spinner spinner-border" role="status">
                <span className="sr-only">Loading...</span>
        </div>
        </div>)
}

export default CustomLoader;
