import axios, { Axios } from "axios";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { get, isString } from "lodash";
import { cookieKeysEnum } from "../models/common.models";
import moment from "moment";
import Cookies from "universal-cookie";
import { setCurrentLocationStorage } from "../common.actions";
import { MODULES, NAVIGATION, NAVIGATIONHEADER, PAGE_CONFIG } from "../models/navigation.models";
export const Blank_Space_Check_Regex = /^(?=.*\S).+$/;
export const Name_Regex = /^[A-Za-z]*$/;
export const NameWithWhiteSpace_Regex = /^[a-zA-Z ]*$/;
export const AlphaNumeric_Regex = /^[A-Za-z0-9]*$/;
export const Number_Regex = /^[0-9]*$/;
export const PositiveRegex = /^\d+(\.\d{1,2})?$/;
export const MinDate = "01/01/1900";
export const minDateforReport = "01/01/2017";
export const COOKIES = new Cookies();

const encryptDecryptKey = "aes-256-cbc";

export const encrypt = (text: any) => {
  try {
    if (text) {
      const ciphertext = AES.encrypt(JSON.stringify(text), process.env.REACT_APP_STORAGE_VALUE as string).toString();
      return ciphertext;
    }
    return text;
  } catch (e) {
    console.log("Exception in Encryption", e);
  }
};

export const decrypt = (text: any) => {
  try {
    if (text) {

      const bytes = AES.decrypt(text, process.env.REACT_APP_STORAGE_VALUE as string);
      const decryptedData = JSON.parse(bytes.toString(Utf8));
      return decryptedData;
    }

    return text;
  } catch (e) {
    console.log("Exception in Decryption", e);
  }
};

export const cacheManager = {
  getItem: (key: any, isDecrypt = true) => {
    if (isDecrypt) {
      return decrypt(window.localStorage.getItem(key));
    } else {
      return window.localStorage.getItem(key);
    }
  },
  setItem: (key: any, value: any) => {
    let string = isString(value) ? encrypt(value) : encrypt(JSON.stringify(value));
    window.localStorage.setItem(key, string);
  },
  removeItem: (key: any) => {
    window.localStorage.removeItem(key);
  },
  clearSession: () => {
    window.localStorage.clear();
  },
};

export const cookiesManager = {
  setCookie: (key: any, value: any, isParentOrigin: any = true) => {
    let _date = new Date();
    _date.setTime(_date.getTime() + 60 * 60 * 24 * 1000); // TODO : Remove Cookies in 24Hrs.
    let options = {
      path: "/",
      expires: _date,
      domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname,
      secure: window.location.hostname === "localhost" ? false : true,
    };
    COOKIES.set(key, encrypt(value), options);
  },
  getCookie: (key: any, isDecrypt: any = true, isParentOrigin: any = true) => {
    let options: any = {
      path: "/",
      domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname,
      secure: window.location.hostname === "localhost" ? false : true,
    };
    if (isDecrypt) {
      return decrypt(COOKIES.get(key, options));
    } else {
      return COOKIES.get(key, options);
    }
  },
  removeCookie: (key: any, isParentOrigin: any = true) => {
    let options = {
      path: "/",
      domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname,
      secure: window.location.hostname === "localhost" ? false : true,
    };
    COOKIES.remove(key, options);
  },
};

export function utcDateTimeToLocalDateTime(date: any) {
  return moment.utc(date).local().format("MM/DD/YYYY hh:mm A");
}

export function utcDateTimeSecondToLocalDateTime(date: any, format = "MM/DD/YYYY hh:mm:ss A") {
  return moment.utc(date).local().format(format);
}

// Change format to MMM dd, yyyy
export function dateFormatToStringWithDateAndTime(date: any) {
  date = utcDateTimeToLocalDateTime(date);
  return moment(date).format("ll") + " at " + moment(date).format("LT");
}

export const getCookie = (key: any) => {
  let name = key + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getFileType = (fileName: any) => {
  let lastIndex = fileName.lastIndexOf(".");

  if (fileName.length > lastIndex + 1 && lastIndex > 0) {
    return fileName.substring(lastIndex + 1).toLowerCase();
  }

  return fileName;
};

export const getCurrentlyShowingItemsInGrid = (pageNumber: any, pageSize: any, totalItems: any) => {
  if (pageNumber > 0 && pageSize > 0 && totalItems > 0) {
    let startIndex = pageNumber * pageSize - pageSize + 1;
    let lastIndex = totalItems >= pageNumber * pageSize ? pageNumber * pageSize : totalItems;

    return `${startIndex} - ${lastIndex} of ${totalItems} items`;
  }

  return "";
};

export const scrollToTopOfPage = () => {
  let scrollElement = document.getElementById("root");

  if (scrollElement) {
    scrollElement.scrollTop = 0;
  }
};

// Extract a query string value from the url
export const extractValueFromUrl = (url: any, paramName: any) => {
  let regex: any;
  if (url && url.indexOf(paramName) === 0) {
    regex = new RegExp(paramName + "=([^&#]*)", "i");
  } else {
    regex = new RegExp("[?&]" + paramName + "=([^&#]*)", "i");
  }
  let string = regex.exec(url);
  return string ? string[1] : null;
};

export const getMessage = (messageList: any, message: any) => {
  return get(messageList, message);
};

export const getSortOrderFromHeaderClick = (event: any) => {
  let orderType = 0; // 0 for Ascending order, 1 for descending order

  if (event.target.attributes.getNamedItem("data-order")) {
    orderType = event.target.attributes.getNamedItem("data-order").value;
  } else {
    orderType = event.target.parentElement.attributes.getNamedItem("data-order").value;
  }

  return orderType;
};

export const resetHeaderSortOrder = (list: any) => {
  list.map((item: any) => {
    item.orderBy = 0;
    item.orderByIcon = 0;
  });
};

export const formatListDataForAutoSearch = (list: any, column: any = null) => {
  if (list && list.length > 0) {
    let result: any = [];
    list.map((item: any) => {
      result.push({ title: item.Title || item.text || item[column] });
    });

    return result;
  }
  return [];
};

export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const generateUUIDForAutofill = () => {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// Replace String at Selected Index
export const replaceAt = (string: any, index: any, replacement: any) => {
  return string.substr(0, index) + replacement + string.substr(index + replacement.length);
};

// Validate Params Keys while Url Comes from Request
export const validateUrlParams = (routeUrls: any, currentPath: any) => {
  currentPath = currentPath && replaceAt(currentPath, 0, " ");
  currentPath = currentPath && currentPath.trim();
  let routeUrlSplitParamsArray = routeUrls && routeUrls.split("/");
  let currentUrlSplitParamsArray = currentPath && currentPath.split("/");
  if (routeUrls.indexOf("/:") > -1 && currentPath.indexOf(routeUrlSplitParamsArray[0]) > -1 && currentPath.indexOf(routeUrlSplitParamsArray[1]) > -1) {
    for (let i = 0; i < routeUrlSplitParamsArray.length; i++) {
      if (currentPath.indexOf(routeUrlSplitParamsArray[i]) === -1) {
        routeUrlSplitParamsArray[i] = currentUrlSplitParamsArray[i];
      }
    }
    return routeUrlSplitParamsArray && routeUrlSplitParamsArray.join("/");
  }
  return routeUrls;
};

// Validate Request Url For Permission
export const validateNavigationFromUrl = (currentPath: any) => {
  const beautifyCurrentPath = (url: any) => url !== "" && url.replace("/", "");
  let navigationModal: any = NAVIGATIONHEADER;
  let result: any = [];
  if (navigationModal && navigationModal.length > 0) {
    for (let i: any = 0; i < navigationModal.length; i++) {
      let modulesItems = navigationModal[i][MODULES[i]];
      if (modulesItems && modulesItems.length > 0) {
        result = modulesItems.filter((itemsList: any) => {
          return currentPath === validateUrlParams(itemsList.routeUrl, currentPath);
        });
        if (result && result.length > 0) {
          return { selectedSingleResult: result, selectedFullResult: modulesItems, currentSearchResult: result[0] };
        }
      }
    }
  }
};

// Filter Permission Url if Allow
export const filterPermissionUrl = (requestUrl: any, method: any = undefined) => {
  let permissions = cacheManager.getItem("privilegeRoleModel") && JSON.parse(cacheManager.getItem("privilegeRoleModel")).length > 0 && get(JSON.parse(cacheManager.getItem("privilegeRoleModel"))[0], "permissions");
  let isPageAuthorized =
    permissions &&
    permissions.length > 0 &&
    permissions.filter((items: any) => {
      if (method) {
        let hasAccess = false;
        if (Array.isArray(requestUrl.oslUrl)) {
          hasAccess = requestUrl.oslUrl.filter((x: any) => x.url == items.resource && items.methods.indexOf(x.verb) > -1 && items.action === "allow").length > 0;
        } else {
          hasAccess = items.methods.indexOf(method) > -1 && requestUrl.oslUrl === items.resource && items.action === "allow";
        }

        // Check for sub routes in case not found in parent
        if (!hasAccess) {
          hasAccess =
            items.subRoutes.length > 0 &&
            items.subRoutes.find((subItem: any) => {
              if (method) {
                return subItem.methods.indexOf(method) > -1 && requestUrl.oslUrl === subItem.resource && subItem.action === "allow";
              }

              return requestUrl.oslUrl === subItem.resource && subItem.action === "allow";
            });
        }
        return hasAccess;
      }
      return requestUrl.oslUrl === items.resource && items.action === "allow";
    });
  return isPageAuthorized;
};

// Validate Auth or Not
export const allowPermissionForAuth = (isPageAuthorized: any) => {
  if (isPageAuthorized && isPageAuthorized.length > 0) {
    return true;
  }
  return false;
};

// Set Route Urls
export const routeNavigationUrl = (page: any, permissionType: any = null, paramsKey: any = []) => {
  // Permission Type Could be Null in case of Directly generaing Unique Keys on Page Params;
  let keys = permissionType !== null ? `${PAGE_CONFIG.uniqueKey(page, permissionType)}` : `${page}`; // Generated Keys for Private Routes
  const setParamsOnUrlRoutes = (selectedUrl: any) => {
    let ParamsIndex = -1;
    let url = selectedUrl && selectedUrl.length > 0 && selectedUrl[0].routeUrl;
    url = url && url.split("/");
    if (url && url.length > 0) {
      for (let i = 0; i < url.length; i++) {
        if (url[i].indexOf(":") > -1) {
          ParamsIndex++;
          url[i] = paramsKey[ParamsIndex];
        }
      }
    }
    return url && url.join("/");
  };
  let moduleUrls: any = [];
  NAVIGATION.map((moduleItem: any, index: any) => moduleItem[MODULES[index]]).map((result: any) => {
    result.map((items: any) => items.uniqueKey === keys && moduleUrls.push(items));
  });
  if (moduleUrls && moduleUrls.length > 0) {
    if (paramsKey.length > 0) {
      return `/${setParamsOnUrlRoutes(moduleUrls)}`;
    }
    return `/${moduleUrls[0].routeUrl}`;
  }
  return "/";
};

// Get Additional Privilege of Compoenent
export const getModulesAdditionalPrivilege = (selectedUrl: any) => {
  let additionalPermission: any = [];
  NAVIGATIONHEADER && NAVIGATIONHEADER.map((moduleItem: any, index: any) => moduleItem[MODULES[index]] && moduleItem[MODULES[index]].map((subModuleItems: any, subIndex: any) => selectedUrl.additionalPermission && selectedUrl.additionalPermission.length > 0 && selectedUrl.additionalPermission.indexOf(subModuleItems.uniqueKey) > -1 && additionalPermission.push(subModuleItems)));
  return additionalPermission;
};

// Validate All User Permission on Page
export const checkUserPermissionOnPage = (allowedPermission: any, page: any, permissionType: any) => {
  // Generated Keys for Private Routes
  let keys = PAGE_CONFIG.uniqueKey(page, permissionType);
  return allowedPermission && get(allowedPermission, [keys]);
};

// TODO : fetch azure ad code
export const azureAdFetchCode = () => {
  // Validate location get from params while called
  setCurrentLocationStorage();
  let azureTempleteURL = process.env.REACT_APP_AZURE_ROOT_URL + "/<TENENT_ID>/oauth2/authorize?response_type=code&client_id=<CLIENT_ID>&redirect_uri=<REDIRECT_URL>&state=<STATE>&resource=<RESOURCE>";
  azureTempleteURL = azureTempleteURL.replace("<TENENT_ID>", process.env.REACT_APP_TENANT_ID as string);
  azureTempleteURL = azureTempleteURL.replace("<CLIENT_ID>", process.env.REACT_APP_AUTH_CLIENT_ID as string);
  azureTempleteURL = azureTempleteURL.replace("<REDIRECT_URL>", process.env.REACT_APP_REDIRECT_URI as string);
  azureTempleteURL = azureTempleteURL.replace("<STATE>", `${encrypt(Math.random().toString(36) + ":" + getUserAgent().name)}`);
  azureTempleteURL = azureTempleteURL.replace("<RESOURCE>", process.env.REACT_APP_OSL_AD_AUTH_APP_CLIENT_ID as string);
  return azureTempleteURL;
};

export const clearAzureAdAuthCodeAction = () => {
  // TODO : Remove forcefully Clean Session.
  cacheManager.clearSession();
  cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
  let url = process.env.REACT_APP_AZURE_LOGOUT_URL + "/" + process.env.REACT_APP_TENANT_ID + "/oauth2/logout?post_logout_redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "/logout";
  return url;
};
export const validateLeftMenuNavigation = (navigation: any, isParent: boolean = false) => {
  let permissions = cacheManager.getItem("privilegeRoleModel") && JSON.parse(cacheManager.getItem("privilegeRoleModel")).length > 0 && get(JSON.parse(cacheManager.getItem("privilegeRoleModel"))[0], "permissions");
  let isPageAuthorized = false;
  if (Array.isArray(navigation.oslUrl) && isParent && permissions && permissions.length > 0) {
    isPageAuthorized = permissions.filter((items: any) => navigation.oslUrl.filter((navitem: any) => navitem.url == items.resource && items.methods.indexOf(navitem.verb) > -1).length > 0 && items.action === "allow").length > 0;
  } else if (!isParent && permissions && permissions.length > 0) {
    isPageAuthorized = permissions.filter((items: any) => items.methods.indexOf(navigation.oslMethod) > -1 && navigation.oslUrl === items.resource && items.action === "allow").length > 0;
  }
  return isPageAuthorized;
};

// Navigation Permission Validate
export const validateNavigationPermission = (featureFlag: any) => {
  const NAVIGATIONRESULT = featureFlag?.DmsAppFeature ? NAVIGATIONHEADER : NAVIGATION;
  let navResult: any = [];
  // Validate Parent Module Permission
  const validateParentModulePermission = async (parentItem: any, index: any) => {
    if (parentItem[MODULES[index]].length > 0) {
      return parentItem[MODULES[index]].filter((result: any) => allowPermissionForAuth(filterPermissionUrl(result, result.oslMethod)));
    }
    return [];
  };
  // Validate Sub Module Permission
  const validateSubModulePermission = (parentItem: any, index: any) => {
    if (parentItem[MODULES[index]].length > 0) {
      parentItem[MODULES[index]].map((result: any) => {
        if (!allowPermissionForAuth(filterPermissionUrl(result, result.oslMethod))) {
          // Check SubModule Permission
          result.isVisibleOnNavigation = false;
        }
      });
    }
  };
  // Get each navigation values.
  return new Promise((resolve) => {
    if (NAVIGATIONRESULT && NAVIGATIONRESULT.length > 0) {
      navResult = NAVIGATIONRESULT.map((item: any, index: any) => {
        let isParentModulePermission = validateParentModulePermission(item, index);
        isParentModulePermission.then((result) => {
          // Check Module Permission
          if (result && result.length === 0) {
            item.isVisibleOnNavigation = false;
          }
        });
        // Check Sub Module Permission
        validateSubModulePermission(item, index);
        return item;
      });
    }
    resolve(navResult);
  });
};

export const setLoginDataToCacheManager = (response: any, loginActionType: any = null) => {
  // Perform login Action on Type.
  switch (loginActionType) {
    case "DELETE": // For Delete Token
      cacheManager.removeItem("privilegeRoleModel");
      cacheManager.removeItem("userDetail");
      cacheManager.removeItem("isReactAppAuthorized");
      cacheManager.removeItem(cookieKeysEnum.accessToken);
      cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
      break;
    case "LOGIN": // For Add login/Azure Ad Login Request
      cacheManager.removeItem("current-location"); // TODO : Remove current location from storage.
      cacheManager.setItem("privilegeRoleModel", response.data.privilege.privilegeRoleModel);
      cacheManager.setItem("userDetail", response.data.privilege.userDetail);
      cacheManager.setItem("isReactAppAuthorized", true);
      cacheManager.setItem(cookieKeysEnum.accessToken, response.data.accessToken);
      break;
    case "REFRESH": // For Refresh token Request Update
      if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
        if (cacheManager.getItem(cookieKeysEnum.accessToken)) {
          cacheManager.removeItem(cookieKeysEnum.accessToken);
        }
        cacheManager.setItem(cookieKeysEnum.accessToken, response.data.accessToken);
      }
      break;
    default:
      break;
  }
};

export const isReactAppAuthorized = () => {
  if (cacheManager.getItem("isReactAppAuthorized")) {
    // VALIDATE THE TOEKN EXIST OR NOT
    if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true" && !isTokenExistInCookies()) {
      cacheManager.clearSession(); // Check Access Token Should be Exist In Case of Azure AD
    }
    return true;
  }
  return false;
};

export const getCurrentUserEmail = () => {
  let userDetail = cacheManager.getItem("userDetail") && JSON.parse(cacheManager.getItem("userDetail"));
  if (userDetail !== null) {
    // GET CURRENT LOGGED IN USER EMAIL.
    return get(userDetail, "email");
  }
};

export const isTokenExistInCookies = () => {
  if (cookiesManager.getCookie(cookieKeysEnum["APP_SESSIONID"])) {
    return true;
  }
  return false;
};

export const getDomainFromSubDomain = (hostname: any) => {
  if (window.location.hostname !== "localhost") {
    const dotIndex = hostname.indexOf(".");
    return hostname.substring(dotIndex + 1);
  }
  return hostname;
};

export const noCookiesRedirectToLogin = () => {
  if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true" && !isTokenExistInCookies()) {
    window.location.assign("/");
  }
};

export const callAPI = (url: any, verb: any, data: any, callback: any) => {
  let axioObj: any;
  switch (verb) {
    case "get":
      axioObj = axios.get(url, data);
      break;
    case "post":
      axioObj = axios.post(url, data);
      break;
    case "patch":
      axioObj = axios.patch(url, data);
      break;
    case "delete":
      axioObj = axios.delete(url, data);
      break;
    default:
    // DO Nothing
  }
  axioObj
    .then((response: any) => {
      callback(response);
    })
    .catch((err: any) => {
      callback({});
    });
};

let prevOrgName = "";
let TIMEOUT: any = null;

export const getMinutes = (cTime: any) => moment(cTime, "HH:mm:ss a").get("minutes");

export const isLocalhost = () => {
  return window.location.origin.includes("localhost");
};

// TODO : Get User Agitent (Identify request coming from browser.)
export const getUserAgent = () => {
  const test = (regexp: any) => regexp.test(window.navigator.userAgent);
  switch (true) {
    case test(/edg/i):
      return { name: "Edge", version: "" };
    case test(/trident/i):
      return { name: "IE", version: "" };
    case test(/firefox|fxios/i):
      return { name: "Firefox", version: "" };
    case test(/opr\//i):
      return { name: "Opera", version: "" };
    case test(/ucbrowser/i):
      return { name: "UC", version: "" };
    case test(/samsungbrowser/i):
      return { name: "Samsung", version: "" };
    case test(/chrome|chromium|crios/i):
      return { name: "Chrome", version: "" };
    case test(/safari/i):
      return { name: "Safari", version: "" };
    default:
      return { name: "Other", version: "" };
  }
};

export const commaSeperator = (value: any) => {
  // return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
  return new Intl.NumberFormat("en-US").format(value);
};

export const encodeStreamData = (data: any) => {
  const str =
    data &&
    data.reduce(function (a: any, b: any) {
      return a + String.fromCharCode(b);
    }, "");
  return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
};
export const downloadByUrl = (filename: any, url: any) => {
  fetch(url).then(function (t) {
    return t.blob().then((b) => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    });
  });
};

// Generating year list
export const generateYearList = (yearsForward = 2) => {
  const currentYear = new Date().getFullYear();
  let yearOptionsList = [];
  let key = 0;
  const toYear = currentYear + yearsForward;
  for (let y = currentYear; y <= toYear; y++) {
    yearOptionsList.push({
      key: key,
      text: y,
      value: y
    })
    key++;
  }
  return yearOptionsList;
}

export const setMinDate = () => {
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 3, 0, 1);
  return minDate;
};

// Get previous month with date 01
export const getDateWithPreviousMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  date.setDate(1);
  return date;
};

export const downloadDocument = (fileDataResponse: any, fileName: any) => {
  const arr = fileDataResponse.content.Body.data;
  const byteArray = new Uint8Array(arr);

  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(new Blob([byteArray], { type: fileDataResponse.content.ContentType }));
  a.download = fileName || "";

  // Append anchor to body.
  document.body.appendChild(a);
  a.click();

  // Remove anchor from body
  document.body.removeChild(a);
};

export const downloadFileByBase64 = (fileContent: any, fileName: any) => {
  let a = window.document.createElement("a");
  a.href = "data:application/octet-stream;base64," + fileContent;
  a.download = fileName || "";
  // Append anchor to body.
  document.body.appendChild(a);
  a.click();
  // Remove anchor from body
  document.body.removeChild(a);
};

//get formatted date without slash
export const formatDateWithoutSlash = (date: any, dateFormat: any = "MMDDYYYY") => {
  return moment(date).format(dateFormat);
};

// TODO : enum for committee levels
export const COMMITTEE_LEVELS = {
  MAINCOMMITTEE: 1,
  SUBCOMMITTEE: 2,
  SECTION: 3,
};

export const MEMBERSHIP_REQUEST_TYPE = {
  NEWMEMBER: "New Member",
  RENEW: "Renewal",
  REINSTATE: "Reinstate",
  STUDENT_UPGRADE: "Student Upgrade",
};

export const EMAIL_TEMPLATE_NAME = {
  CHANGE_OF_EMPLOYMENT: "Change_Of_Employment_Email",
  CHANGE_OF_COMPANY_ADDRESS_EMAIL: "Change_Of_Company_Address_Email",
};

export const FORM_TYPE_ORG_UPDATE = {
  Member: "Member",
  Organization: "Organization",
};

export const canadaZipCodeFix = (str: any) => {
  if (str.length > 0 && str.includes("-")) return str.replace("-", " ");
  return str;
};

export const searchEventList = (self: any, trimmedValue: any) => {
  let { eventList } = self.state;
  let eventSearchList =
    eventList && eventList.length > 0
      ? eventList.filter((item: any) => {
        return (item.EventName && item.EventName.indexOf(trimmedValue) >= 0) || (item.EventName && item.EventName.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0);
      })
      : [];
  if (eventSearchList.length > 0 && trimmedValue.length > 0) {
    self.setState({
      isLoading: false,
      eventSearchList,
      isOpen: true,
      showNoResultsMessage: eventSearchList.length === 0,
    });
  } else if (!trimmedValue) {
    self.setState({ eventSearchList: self.state.eventList }, () => {
      let { eventSearchList } = self.state;
      let showNoResultsMessage = eventSearchList.length > 0 ? false : true;
      self.setState({ isLoading: false, eventSearchList, isOpen: true, showNoResultsMessage: showNoResultsMessage });
    });
  } else if (eventSearchList.length === 0 && trimmedValue.length > 0) {
    self.setState({
      isLoading: false,
      eventSearchList: [],
      isOpen: true,
      showNoResultsMessage: eventSearchList.length === 0,
    });
  } else {
    self.setState({ isLoading: false, eventSearchList: [], isOpen: false });
  }
};

export const openApiPathArray = ["/master/featureflag"];

export const scrollToErrorOnFormHandler = () => {
  const fields = "input, select, textarea, .sidebar-selection-input"; // Increase Fields as per requirement.
  const currentFormSelector = document.querySelectorAll("form");
  // Wait for is-Invalid class attached into the fields.
  setTimeout(() => {
    // Get all form into single screen.
    for (let index = 0; index < currentFormSelector.length; index++) {
      if (currentFormSelector[index]) {
        const fieldsElements: any = currentFormSelector[index].querySelectorAll(fields);
        // Get all fields from Form references.
        for (let i = 0; i < fieldsElements.length; i++) {
          if ((fieldsElements[i].classList && fieldsElements[i].classList.value.indexOf("is-invalid") > -1) || (fieldsElements[i].offsetParent && fieldsElements[i].offsetParent.classList && fieldsElements[i].offsetParent.classList.value.indexOf("is-invalid") > -1)) {
            fieldsElements[i].scrollIntoView({ behavior: "smooth", block: "center" });
            return false;
          }
        }
      }
    }
  }, 100);
};

export const getFormattedDate = (date: Date, format = "MM/DD/YYYY") => {
  return moment(date).format(format);
};

export const encodeText = (text: string) => {
  return window.btoa(text);
};

export const formatFileSize = (bytes: number) => {
  if (bytes == 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const scrollToActiveItem = () => {
  const parentCurrent = document.querySelector("#render-table-for-documentlist");
  if (parentCurrent && parentCurrent.querySelectorAll("td").length > 0) {
    const fields = "a"; // Increase Fields as per requirement.
    const currentFormSelector = parentCurrent.querySelectorAll("td");
    // Wait for is-Invalid class attached into the fields.
    setTimeout(() => {
      // Get all form into single screen.
      for (let index = 0; index < currentFormSelector.length; index++) {
        if (currentFormSelector[index]) {
          const fieldsElements: any = currentFormSelector[index].querySelectorAll(fields);
          // Get all fields from Form references.
          for (let i = 0; i < fieldsElements.length; i++) {
            if ((fieldsElements[i].classList && fieldsElements[i].classList.value.indexOf("activeDocumentForDocumentInfo") > -1) || (fieldsElements[i].offsetParent && fieldsElements[i].offsetParent.classList && fieldsElements[i].offsetParent.classList.value.indexOf("activeDocumentForDocumentInfo") > -1)) {
              fieldsElements[i].scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }
        }
      }
    }, 100);
  }
};

export const downloadFile = (data: any) => {
  let url = data.DownloadUrl;
  let a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", data.DocumentName);
  a.setAttribute("target", "_blank");
  document.body.append(a);
  a.click();
};

export const timeLocal = (date: string) => {
  return moment.utc(date).local()
    .format('hh:mm A');
}

export const getApiUniqueRequestId = (url: string) => {
  const uniqueRequestIds = JSON.parse(cacheManager.getItem('uniqueRequestIdForApis'));
  for (let key in uniqueRequestIds) {
    if (url === key) {
      return uniqueRequestIds[key]
    }

  }
}

export default {
  Blank_Space_Check_Regex,
  AlphaNumeric_Regex,
  Name_Regex,
  PositiveRegex,
  Number_Regex,
  getCookie,
  getFileType,
  getCurrentlyShowingItemsInGrid,
  scrollToTopOfPage,
  utcDateTimeToLocalDateTime,
  utcDateTimeSecondToLocalDateTime,
  extractValueFromUrl,
  getMessage,
  getSortOrderFromHeaderClick,
  resetHeaderSortOrder,
  formatListDataForAutoSearch,
  generateUUID,
  generateUUIDForAutofill,
  validateNavigationFromUrl,
  filterPermissionUrl,
  allowPermissionForAuth,
  routeNavigationUrl,
  getModulesAdditionalPrivilege,
  azureAdFetchCode,
  validateNavigationPermission,
  setLoginDataToCacheManager,
  isReactAppAuthorized,
  clearAzureAdAuthCodeAction,
  getCurrentUserEmail,
  cookiesManager,
  isTokenExistInCookies,
  noCookiesRedirectToLogin,
  getMinutes,
  getUserAgent,
  commaSeperator,
  encodeStreamData,
  downloadByUrl,
  downloadDocument,
  downloadFileByBase64,
  formatDateWithoutSlash,
  searchEventList,
  openApiPathArray,
  scrollToErrorOnFormHandler,
  getFormattedDate,
  encodeText,
  formatFileSize,
  scrollToActiveItem,
  downloadFile,
  timeLocal,
  getApiUniqueRequestId
};
