import React, { useContext, useEffect, useState } from "react";
import lodash from "lodash";
import utilCommon, { isReactAppAuthorized } from "../helpers/util-common";
import { Link } from "react-router-dom";
import { validateUrlParams } from "../helpers/util-common";
import { MODULES } from "../models/navigation.models";
import { FeatureFlag } from "../provider/flagFeature";

interface Props {
  history: any;
}
const NavigationMenu = (props: Props) => {
  const { history } = props;
  const featureData = useContext(FeatureFlag);
  const isUserAuthorized = isReactAppAuthorized();
  const [navigationMenu, setNavigationMenu] = useState([]);
  useEffect(() => {
    if (isUserAuthorized) {
      utilCommon.validateNavigationPermission(featureData?.FlagFeaturesData).then((result: any) => {
        setNavigationMenu(result);
      });
    }
  }, [featureData]);
  // Validate Active Items : Returns Active or Not
  const activeItems = (items: any, navIndex: any) => {
    let currentRoute = history?.location?.pathname && history?.location?.pathname; // Get the key from the route;
    if (currentRoute && validateUrlParams(items.routeUrl, currentRoute) === currentRoute) {
      return true;
    } else if (currentRoute && items[MODULES[navIndex]] && items[MODULES[navIndex]].length > 0) {
      let filterResult = items[MODULES[navIndex]].filter((filterItems: any) => {
        return validateUrlParams(filterItems.routeUrl, currentRoute) === currentRoute.substring(1);
      });
      return filterResult && filterResult[0];
    }
  };
  // Check Parent Url has exist hash
  const validateRouteUrl = (url: any) => {
    if (url && url.indexOf("#") > -1) {
      return url;
    }
    return `/${url}`;
  };
  // Render Parent Menu
  const renderParentMenu = (item: any, navIndex: any) => {
    return (
      item.isVisibleOnNavigation && (
        <li key={`${navIndex}_${lodash(item).get("ModuleName")}`}>
          <a className="" href={lodash(item).get("isExternalUrl") ? lodash(item).get("routeUrl") : validateRouteUrl(lodash(item).get("routeUrl"))} data-testid="navigationParent-id">
            {lodash(item).get("ModuleName")}
          </a>
          {item.hasNavChild && item[MODULES[navIndex]] && item[MODULES[navIndex]].length > 0 && (
            <ul className="subMenu" key={item.uniqueKey}>
              {renderChildMenu(item[MODULES[navIndex]])} {lodash(item).get("isExternalUrl")}
            </ul>
          )}
        </li>
      )
    );
  };
  // Render Child Menu
  const renderChildMenu = (subItems: any) => {
    return (
      subItems &&
      subItems.length > 0 &&
      subItems.map((items: any, index: number) => {
        return (
          items.isVisibleOnNavigation && (
            <li key={`${index}_${lodash(items).get("title")}`}>
              <a className="" href={lodash(items).get("isExternalUrl") ? lodash(items).get("routeUrl") : validateRouteUrl(lodash(items).get("routeUrl"))} data-testid="navigationChild-id">
                {lodash(items).get("title")}
              </a>
              {/* <Link to={lodash(items).get('isExternalUrl')==false ? { pathname: lodash(items).get('routeUrl') } : validateRouteUrl(lodash(items).get('routeUrl'))}>{lodash(items).get('title')}</Link> */}
            </li>
          )
        );
      })
    );
  };

  return (
    <div className="topMenuBar">
      <div className="container">
        <div className="menuWrapper dms-Menu">
          <ul>
            {navigationMenu &&
              navigationMenu.length > 0 &&
              navigationMenu.map((item: string, navIndex: number) => {
                return renderParentMenu(item, navIndex);
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavigationMenu;
