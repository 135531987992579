import React from "react";
import { Image } from "react-bootstrap";
import logoImg from "../assets/images/astm-internal.png";
const PrivilegeNotFound = () => (
  <div className="loginPage privligeWrap">
    <figure className="logo">
      <Image src={logoImg} alt="ASTM Logo" />
    </figure>

    <div className="loginWrap">
      <div className="noPrivilege">
        <span className="dontSign">
          <i className="far fa-exclamation-triangle"></i>
        </span>

        <span className="permissionSub">You do not have permission to view this page. </span>
      </div>
    </div>
  </div>
);

export default PrivilegeNotFound;
