import axios from "axios";
import { cacheManager, callAPI, clearAzureAdAuthCodeAction, cookiesManager, getApiUniqueRequestId, noCookiesRedirectToLogin } from "./helpers/util-common";
import { get } from "lodash";
import * as types from "./action-type";
import getApiUrl from "./helpers/api-urls";
import { decrypt, encrypt, getUserAgent } from "./helpers/util-common";
import { AppGridIds, cookieKeysEnum, S3_URL_TYPE } from "./models/common.models";

// Commented For Future Use
// export const hideMessage = (dispatch: any) => {
//   dispatch(setMessage(false, "200"));
// };

export const setLoader = (flag: any) => ({
  type: types.SET_LOADER,
  flag,
});

export const setminiLoader = (flag: any) => ({
  type: types.SET_MINI_LOADER,
  flag,
});


export const setMessage = (flag: any, code: any, uniqueRequestId = null, message = "") => ({
  type: types.SET_MESSAGE,
  flag,
  code,
  message,
  uniqueRequestId
});
export const showSuccessToastMesage = (flag: any, code: any, message = "") => ({
  type: types.SHOW_SUCCESS_MESSAGE,
  flag,
  code,
  message
});

export const showDissmissibleToastMesage = (flag: any, code: any, dismissibleMessage = "", dispatch?: any) => (
  dispatch({ type: types.SHOW_DISMISSIBLE_MESSAGE, flag, code, dismissibleMessage }));

// Commented For Future Use
// export const showErrorPopup = (flag: any, data: any) => ({
//   type: types.SHOW_ERROR_POPUP,
//   flag,
//   data,
// });
export const StorCurrentLocation = (dispatch: any, data: string) => {
  dispatch({ type: types.STORE_CURRENT_LOCATION, data });
};

export const showHideCreatBallotFolderModelAction = (dispatch: any, flag: boolean) => {
  dispatch({ type: types.SHOW_CREATE_BALLOT_FOLDER, flag })
}

export const showHideBallotManulaPublish = (dispatch: any, flag: boolean) => {
  dispatch({ type: types.SHOW_HIDE_BALLOT_MANUAL_PUBLISHSH, flag })
}

export const setManualZipStatusInfo = (dispatch: any, ballotManualZipInfo: any) => {
  dispatch({ type: types.MANUAL_ZIP_STATUS_INFO, ballotManualZipInfo })
}
//Commented For Future Use
// export const showAlertPopup = (flag: any, data: any) => ({
//   type: types.SHOW_ALERT_POPUP,
//   flag,
//   data,
// });

// Action for calling logout.
export const logout = (dispatch: any) => {
  let url = getApiUrl("login", "logout");
  dispatch(setLoader(true));
  axios
    .post(url)
    .then((response) => {
      if (response.data.status) {
        // Clear all cache from react app.
        cacheManager.clearSession();
        // Validate if Logout from Azure AD.
        if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
          cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
          setCurrentLocationStorage();
          setTimeout(() => {
            window.location.assign(clearAzureAdAuthCodeAction());
          }, 500);
        } else {
          window.location.assign("/login");
        }
      } else {
        const uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(setMessage(true, response.data.message, uniqueRequestId));
      }
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(setLoader(false));
      // Custom default code for autologout
      noCookiesRedirectToLogin();
    });
};

export const currentLocationStorage = () => {
  if (cacheManager.getItem("current-location")) {
    return { isLocation: true, location: cacheManager.getItem("current-location") };
  }
  return { isLocation: false, location: null };
};

export const setCurrentLocationStorage = () => {
  if (window.location.href) {
    let location = window.location.href.replace(/.*\/\/[^\/]*/, "");
    let allowedRoutes = ["/renewal-tasks", "/admin", "/user", "/roles", "/member-management", "/committee-management", "/work-item-admin", "/ballot-admin"];
    let isAllowed = allowedRoutes.filter((x) => location.includes(x));
    if (isAllowed && isAllowed.length) {
      cacheManager.setItem("current-location", window.location.href.replace(/.*\/\/[^\/]*/, ""));
    }
  }
};

export const getPermissionOnPageAction = (Input: any, groupInput: any) => ({
  type: types.ALLOW_PERMISSION_FOR_PAGE,
  Input,
  groupInput,
});

// TODO : initiate Azure Request
export const initiateAzureRequestAction = (code: any, sessionState: any, authState: any, cb: any, dispatch: any) => {
  // TODO : Start code for init azure request.
  dispatch(setLoader(true));
  // TODO : Call Request for Init Azure in OSL.
  let url = getApiUrl("login", "initAzure");
  let authCode = encrypt(`${code}:${sessionState}:${decrypt(decodeURIComponent(authState))}`);
  callAPI(url, "post", { code: authCode }, (response: any) => {
    if (response.data && response.data.success) {
      cookiesManager.setCookie(cookieKeysEnum.APP_SESSIONID, sessionState);
      cb(true);
    } else {
      dispatch(setLoader(false));
      cb(false);
    }
  });
};

export const flagFeature = (callback: any, dispatch: any) => {
  let url = getApiUrl("openAPI", "flagFeature", true);
  dispatch(setLoader(true));
  callAPI(url, "get", null, (response: any) => {
    dispatch(setLoader(false));
    if (response.data && response.data.Status) {
      callback(response);
    } else {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(setMessage(true, response.data.Message || "8166", uniqueRequestId));
    }
  });
};

export const getBallotListAction = (isFiltered: boolean, dispatch: any, callback: (response: any) => void) => {
  dispatch(setLoader(true));
  let url = getApiUrl("ballotAdmin", "getBallotDetailsByLetterBallot");
  url = `${url}/${isFiltered}`;
  axios
    .get(url)
    .then((response) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(setLoader(false));
      if (response.data.status) {
        callback(response.data.content);
      } else {
        dispatch(setMessage(true, "7025", uniqueRequestId));
      }
    })
    .catch((err) => {
      dispatch(setLoader(false));
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(setMessage(true, "7025", uniqueRequestId));
    });
};

export const getDocumentStatusByCategoryAction = (dispatch: any, categoryId: number, callback: (response: any) => void, isCallback = false) => {
  dispatch(setLoader(true));
  let url = getApiUrl("documents", "getDocumentStatusByCategory");
  url = `${url}/${categoryId}`;
  axios
    .get(url)
    .then((response) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(setLoader(false));
      if (response.data.status) {
        dispatch({ type: types.GET_DOCUMENT_STATUS_BY_CATEGORY, data: response.data.content });
        if (isCallback) {
          callback(response.data.content);
        }
      } else {
        dispatch(setMessage(true, "7025", uniqueRequestId));
      }
    })
    .catch((err) => {
      dispatch(setLoader(false));
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(setMessage(true, "7025", uniqueRequestId));
    });
};

export const setFlagForBallotNavigation = (ballotFlag: number) => ({
  type: types.SET_BALLOT_FLAG,
  ballotFlag
});

export default {
  // hideMessage,
  setLoader,
  setMessage,
  showSuccessToastMesage,
  // showErrorPopup,
  StorCurrentLocation,
  // showAlertPopup,
  logout,
  getPermissionOnPageAction,
  currentLocationStorage,
  setCurrentLocationStorage,
  initiateAzureRequestAction,
  getDocumentStatusByCategoryAction,
  showHideCreatBallotFolderModelAction,
  setFlagForBallotNavigation
};
