import axios from "axios";
import * as types from "../../action-type";
import commonActions, { setminiLoader } from "../../common.actions";
import { cacheManager, getApiUniqueRequestId, scrollToActiveItem } from "../../helpers/util-common";
import getApiUrl from "../../helpers/api-urls";
import { LEFT_NAVIGATION_PAGE_URL } from "../../models/navigation.models";
import { categoryIdForDocuments } from "../../models/common.models";

/** ACTION FOR CALLING ALL DOCUMENTS LIST */
export const getAllDocumentsListAction: any = (dispatch: any, data: any, url: string, callback: any) => {
  const uploadedFileName = cacheManager.getItem("fileName") && JSON.parse(cacheManager.getItem("fileName"));
  const uploadAsTermsDoc = cacheManager.getItem("uploadTermDocument") && cacheManager.getItem("uploadTermDocument");
  if (uploadedFileName) {
    dispatch({ type: types.FILE_NAME, data: uploadedFileName.length > 0 ? uploadedFileName : uploadedFileName.fileName });
    if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.AGENDAS)) {
      dispatch(commonActions.setMessage(true, uploadedFileName.length > 0 ? "2040" : "2004"));
    } else if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.MINUTES)) {
      dispatch(commonActions.setMessage(true, uploadedFileName.length > 0 ? "2041" : "2007"));
    } else if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.COMMITTES)) {
      dispatch(commonActions.setMessage(true, "2014"));
    } else if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.BALLOT_QUEUE)) {
      dispatch(commonActions.setMessage(true, "2030"));
    } else if (window.location.pathname.includes(LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS)) {
      JSON.parse(uploadAsTermsDoc) ? dispatch(commonActions.setMessage(true, "2034")) : dispatch(commonActions.setMessage(true, "2033"));
    } else {
      dispatch(commonActions.setMessage(true, "2023"));
    }
  }
  cacheManager.removeItem("fileName");
  cacheManager.removeItem("uploadTermDocument");
  dispatch(setminiLoader(true));
  axios
    .post(url, data)
    .then((response: any) => {
      if (response && response.data.status) {
        callback(response.data.content);
      } else {
        callback({});
      }
    })
    .catch(() => {
      callback({});
      dispatch(commonActions.setLoader(false));
    });
};

export const handleDocumentInfoClickAction: any = (dispatch: any, documentId: string, isDocumentInfo: boolean, displayFileName: string, hasAccess: boolean, currentDocumentChangedStatus: string) => {
  dispatch({
    type: types.CLICK_DOCUMENT_INFO_ICON,
    data: { documentId, isDocumentInfo, displayFileName, hasAccess, currentDocumentChangedStatus },
  });
};

export const handleDocumentDeleteClickAction: any = (dispatch: any, postData: any, callback: any) => {
  dispatch(commonActions.setLoader(true));
  let url: string = "";
  const { DocumentCategoryId } = postData;
  if (DocumentCategoryId == categoryIdForDocuments.agenda) {
    url = getApiUrl("documents", "agendasDocDelete");
  } else if (DocumentCategoryId == categoryIdForDocuments.minutes) {
    url = getApiUrl("documents", "minutesDocDelete");
  } else if (DocumentCategoryId == categoryIdForDocuments.committeesDocuments) {
    url = getApiUrl("documents", "committeeDocDelete");
  } else if (DocumentCategoryId == categoryIdForDocuments.voteAttachment) {
    url = getApiUrl("documents", "voteAttachmentDocDelete");
  } else if (DocumentCategoryId == categoryIdForDocuments.ballotQueue) {
    url = getApiUrl("documents", "ballotQueueDocDelete") + "/1";
  } else if (DocumentCategoryId == categoryIdForDocuments.ballotItems) {
    url = getApiUrl("documents", "ballotQueueDocDelete") + "/3";
  }
  axios
    .delete(url, { data: postData })
    .then((response: any) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content);
        dispatch(commonActions.setMessage(true, "2011"));
        dispatch({ type: types.FILE_NAME, data: [] });
      } else {
        callback({});
        dispatch(commonActions.setMessage(true, "2010", uniqueRequestId));
      }
    })
    .catch((ex) => {
      console.log(ex);
      callback({});
      dispatch(commonActions.setLoader(false));
    });
};

export const getDocumentInfoByIdAction: any = (dispatch: any, data: object, callback: any) => {
  dispatch(commonActions.setLoader(true));
  const url: string = getApiUrl("documents", "getDocumentInfoById");
  axios
    .post(url, data)
    .then((response: any) => {
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content);
      } else {
        callback({});
        // Handle error case
        const uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
      }
    })
    .catch(() => {
      callback({});
      dispatch(commonActions.setLoader(false));
    });
};

export const updateDocumentInfoAction: any = (dispatch: any, data: any, callback: Function) => {
  dispatch(commonActions.setLoader(true));
  const { displaySuccessMessage, ...restData } = data;
  const url: string = getApiUrl("documents", "updateDocumentInfo");
  axios
    .post(url, restData)
    .then((response: any) => {
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        dispatch(commonActions.setMessage(true, "7025", null, displaySuccessMessage));
        dispatch({ type: types.UPDATE_DOCUMENT_LIST, activeDocumentInfoDataId: data.DocumentDataId });
        dispatch({ type: types.FILE_NAME, data: [] });
        callback(response.data.status);
      } else {
        // Handle error case
        const uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
      }
    })
    .catch(() => {
      dispatch(commonActions.setLoader(false));
    });
};

export const updateActiveDocumentInfoDataIdAction: any = (dispatch: any) => {
  dispatch({ type: types.UPDATE_DOCUMENT_LIST, activeDocumentInfoDataId: 0 });
};

export const getDownloadDocumentFileUrlAction: any = (dispatch: any, data: any, callback: Function) => {
  dispatch(commonActions.setLoader(true));
  const { displaySuccessMessage, ...restData } = data;
  const url: string = getApiUrl("documents", "downloadDocumentFileUrl");
  axios
    .post(url, restData)
    .then((response: any) => {
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content);
      } else {
        // Handle error case
        const uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
      }
    })
    .catch(() => {
      dispatch(commonActions.setLoader(false));
    });
};

export const getBallotFolderList: any = (dispatch: any, data: any, callback: Function) => {
  dispatch(commonActions.setLoader(true));
  const url: string = `${getApiUrl("documents", "getBallotQueueList")}/2`;
  axios
    .post(url, data)
    .then((response: any) => {
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content);
      } else {
        // Handle error case
        const uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
      }
    })
    .catch(() => {
      dispatch(commonActions.setLoader(false));
    });
};

export const getBallotFolderStatusList: any = (dispatch: any, data: any, callback: Function) => {
  dispatch(commonActions.setLoader(true));
  const url: string = `${getApiUrl("documents", "ballotFolderStatusList")}`;
  axios
    .get(url, data)
    .then((response: any) => {
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content);
      } else {
        // Handle error case
        const uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
      }
    })
    .catch(() => {
      dispatch(commonActions.setLoader(false));
    });
};

export const setFlagForBallotNavigation: any = (dispatch: any, ballotFlag: number) => {
  dispatch(commonActions.setFlagForBallotNavigation(ballotFlag));
};

export const postBallotFolderDetailPage = (dispatch: any, data: any, callback: (response: any) => void) => {
  dispatch(commonActions.setLoader(true));
  const url: string = `${getApiUrl("documents", "ballotFolderDetailList")}`;
  axios
    .post(url, data)
    .then((response: any) => {
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content?.Data);
      } else {
        // Handle error case
        const uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "Something Went Wrong.", uniqueRequestId));
      }
    })
    .catch(() => {
      dispatch(commonActions.setLoader(false));
    });
};

export const putBallotItemResequence: any = (dispatch: any, data: any, callback: Function) => {
  dispatch(commonActions.setLoader(true));
  const url: string = `${getApiUrl("documents", "ballotItemResequence")}`;
  axios
    .put(url, data)
    .then((response: any) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content);
      } else {
        dispatch(commonActions.setMessage(true, "1400", uniqueRequestId));
      }
    })
    .catch(() => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setMessage(true, "1400", uniqueRequestId));
      dispatch(commonActions.setLoader(false));
      callback(false);
    });
};

export const getLetterBallotListAction = (documentCategoryId: number, dispatch: any, callback: (response: any) => void) => {
  dispatch(commonActions.setLoader(true));
  let url = getApiUrl("documents", "getLetterBallotList");
  url = `${url}/${documentCategoryId}`;
  axios
    .get(url)
    .then((response) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        callback(response.data.content);
      } else {
        dispatch(commonActions.setMessage(true, "1202", uniqueRequestId));
      }
    })
    .catch((err) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setMessage(true, "1202", uniqueRequestId));
      dispatch(commonActions.setLoader(false));
    });
};

export const getBallotFolderZipFileStatus = (ballotId: number, dispatch: any, callback: (response: any) => void) => {
  dispatch(commonActions.setLoader(true));
  let url = getApiUrl("documents", "getBallotFolderZipFileStatus");
  url = `${url}/${ballotId}`;
  axios
    .get(url)
    .then((response) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        callback(response.data.content);
      } else {
        dispatch(commonActions.setMessage(true, "1312", uniqueRequestId));
      }
    })
    .catch((err) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setMessage(true, "1312", uniqueRequestId));
      dispatch(commonActions.setLoader(false));
    });
};

export const getRestrictDocumentDeletionAction = (documentDataId: number, dispatch: any, callback: (response: any) => void) => {
  dispatch(commonActions.setLoader(true));
  let url = getApiUrl("documents", "getRestrictDocumentDeletion");
  url = `${url}/${documentDataId}`;
  axios
    .get(url)
    .then((response) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        callback(response.data.content);
      } else {
        dispatch(commonActions.setMessage(true, "1515", uniqueRequestId));
      }
    })
    .catch((err) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setMessage(true, "1515", uniqueRequestId));
      dispatch(commonActions.setLoader(false));
    });
};

export const ballotFixedHeaderAction: any = (dispatch: any, flag: boolean) => {
  dispatch({
    type: types.BALLOT_FIXED_HEADER,
    data: flag,
  });
};

export const putCommitteeDocumentResequence: any = (dispatch: any, data: any, callback: Function) => {
  dispatch(commonActions.setLoader(true));
  const url: string = `${getApiUrl("documents", "committeeDocResequence")}`;
  axios
    .put(url, data)
    .then((response: any) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content);
      } else {
        dispatch(commonActions.setMessage(true, "1600", uniqueRequestId));
      }
    })
    .catch(() => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setMessage(true, "1600", uniqueRequestId));
      dispatch(commonActions.setLoader(false));
      callback(false);
    });
};

export const handleDeleteBulkBallotQueueAction: any = (dispatch: any, postData: any, callback: any) => {
  dispatch(commonActions.setLoader(true));
  let url: string = getApiUrl("documents", "deleteBulkBallotQueue");
  axios
    .delete(url, { data: postData })
    .then((response: any) => {
      const uniqueRequestId = getApiUniqueRequestId(url);
      dispatch(commonActions.setLoader(false));
      if (response && response.data.status) {
        callback(response.data.content);
        dispatch(commonActions.setMessage(true, "2011"));
        dispatch({ type: types.FILE_NAME, data: [] });
      } else {
        callback({});
        dispatch(commonActions.setMessage(true, "2010", uniqueRequestId));
      }
    })
    .catch((ex) => {
      console.log(ex);
      callback({});
      dispatch(commonActions.setLoader(false));
    });
};
