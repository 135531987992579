import * as type from '../../action-type';

interface DocumentCategoryData {
    documentCategoryID: number;
    name: string;
    status: number,
    metaDataTemplete: string
}

interface InitialState {
    documentCategory: DocumentCategoryData[],
    fileName: string | string[] | null,
    failedFileNameList: string[]
}

const initialState: InitialState = {
    documentCategory: [],
    fileName: null,
    failedFileNameList: []
};

const documentUploadReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case type.DOCUMENT_CATEGORY:
            return {
                ...state,
                documentCategory: action
            };
        case type.FILE_NAME:
            return {
                ...state,
                fileName: action.data
            };
        case type.FAILED_FILE_NAME_LIST:
            return {
                ...state,
                failedFileNameList: action.data
            };

        default:
            return state;
    }
}

export default documentUploadReducer;