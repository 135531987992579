// Login
export const LOGIN = "LOGIN";
export const AZURE_LOGIN = "AZURE_LOGIN";
export const AZURE_REFRESH_TOKEN = "AZURE_REFRESH_TOKEN";
// Common
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_LOADER = "SET_LOADER";
export const SHOW_ERROR_POPUP = "SHOW_ERROR_POPUP";
export const SHOW_ALERT_POPUP = "SHOW_ALERT_POPUP";
export const SET_MINI_LOADER = "SET_MINI_LOADER";
export const SHOW_NOT_FOUND_PAGE = "SHOW_NOT_FOUND_PAGE";
export const HIDE_NOT_FOUND_PAGE = "HIDE_NOT_FOUND_PAGE";
export const GET_APP_USER_LIST = "GET_APP_USER_LIST";
export const IS_SAFARI_AUTOFILL_VISISBLE = "IS_SAFARI_AUTOFILL_VISISBLE";
export const CLICK_DOCUMENT_INFO_ICON = "CLICK_DOCUMENT_INFO_ICON";
export const STORE_CURRENT_LOCATION = "STORE_CURRENT_LOCATION";
export const UPDATE_DOCUMENT_LIST = "UPDATE_DOCUMENT_LIST";
export const GET_DOCUMENT_STATUS_BY_CATEGORY = "GET_DOCUMENT_STATUS_BY_CATEGORY";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const SHOW_DISMISSIBLE_MESSAGE = "SHOW_DISMISSIBLE_MESSAGE";

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_US_STATE_LIST = "GET_US_STATE_LIST";
export const GET_CANADA_STATE_LIST = "GET_CANADA_STATE_LIST";
// Permission Action
export const ALLOW_PERMISSION_FOR_PAGE = "ALLOW_PERMISSION_FOR_PAGE";

//Document Upload
export const DOCUMENT_CATEGORY = "DOCUMENT_CATEGORY";
export const FILE_NAME = "FILE_NAME";
export const FAILED_FILE_NAME_LIST = "FAILED_FILE_NAME_LIST";
// Ballo Folder
export const SHOW_CREATE_BALLOT_FOLDER = "SHOW_CREATE_BALLOT_FOLDER";
export const SET_BALLOT_FLAG = "SET_BALLOT_FLAG";
export const SHOW_HIDE_BALLOT_MANUAL_PUBLISHSH = "SHOW_HIDE_BALLOT_MANUAL_PUBLISHSH";
export const MANUAL_ZIP_STATUS_INFO = "MANUAL_ZIP_STATUS_INFO";
export const BALLOT_FIXED_HEADER = "BALLOT_FIXED_HEADER";
export const SET_LITIGATION_MODAL = "SET_LITIGATION_MODAL";
