import logoImg from "../../assets/images/astm-internal.png";
import React, { useState } from "react";
import lodash from "lodash";
import { Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import NavigationMenu from "../NavigationMenu";
import { useDispatch } from "react-redux";
import { logout } from "../../common.actions";

interface Props {
  userDetail?: any;
  history?: any;
}
export const HeaderMenu: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const applogout = () => {
    logout(dispatch);
  };
  return (
    <header data-testid="headertest">
      <div className="container">
        <div className="headerWrap">
          <div className="logoWrap height-34">
            <a href="">
              <Image src={logoImg} alt="ASTM Logo" />
            </a>
          </div>
          <div className="headerFilterhWrap">
            <div className="profileOptionMenu dropdown">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn astm-btn btn-primary yellow-btn">
                  <i className="fas fa-user-circle astm-icon"></i> {props.userDetail ? lodash(props.userDetail).get("userName", "").toLocaleUpperCase() : ""}
                </Dropdown.Toggle>

                {props.userDetail ? (
                  <Dropdown.Menu>
                    <Dropdown.Item data-testid="logout-id" onClick={applogout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  ""
                )}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <NavigationMenu history={props.history}></NavigationMenu>
    </header>
  );
};
export default HeaderMenu;
