import React, { PureComponent, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import commonActions from "../../common.actions";
import { cacheManager } from "../../helpers/util-common";
import { useSelector } from "react-redux";

interface CustomAlertProps {
  content: { type: string; text: string };
  fileName?: string;
  fileNameList?: string[];
  message: string;
}

const AlertDissmissable: React.FC<CustomAlertProps> = React.memo(({ content, message }: CustomAlertProps) => {
  message = message !== "" ? message : content && content.text;
  const props = useSelector((state: any) => {
    return {
      fileNameList: state.documentUploadReducer.failedFileNameList,
    };
  });
  const [type, setType] = useState(message !== "" ? "danger" : content && content.type);
  const [alertMessage, setAlertMessage] = useState(message);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (props.fileNameList && props.fileNameList.length > 0) {
      let fileMessage = alertMessage.replace("@noOfDocuments", props.fileNameList.length.toString());
      setAlertMessage(fileMessage);
    }
  }, [props.fileNameList.length > 0]);

  return (
    <Alert show={show} onClose={() => setShow(false)} variant={type || "warning"} className={"astm-alert top-toster bg-" + type + "--light"} closeLabel={""} dismissible>
      <div className="flex-box toast-flex-box">
        {type == "success" && (
          <span className="top-toser-ic">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
              <title>icon/16x16/check-circle-green</title>
              <g id="Upload-Documents-UX---CHI-282" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g id="V1_04_Submit-and-upload-another-doc-(single-upload)" transform="translate(-1268, -1073)" fill="#468847">
                  <g id="Upload-In-progress-(Open)" transform="translate(811, 968)">
                    <g id="Files" transform="translate(16, 99)">
                      <g id="icon/16x16/check-circle-green" transform="translate(441, 6)">
                        <path d="M8,0 C3.58170968,0 0,3.58170968 0,8 C0,12.4182903 3.58170968,16 8,16 C12.4182903,16 16,12.4182903 16,8 C16,3.58170968 12.4182903,0 8,0 Z M8,1.5483871 C11.5655484,1.5483871 14.4516129,4.43390323 14.4516129,8 C14.4516129,11.5655484 11.5660968,14.4516129 8,14.4516129 C4.43445161,14.4516129 1.5483871,11.5660968 1.5483871,8 C1.5483871,4.43445161 4.43390323,1.5483871 8,1.5483871 M12.5227097,5.75054839 L11.7957419,5.01770968 C11.6451935,4.86593548 11.4000968,4.86493548 11.2483226,5.01551613 L6.68858065,9.5386129 L4.75980645,7.59419355 C4.60925806,7.44241935 4.36416129,7.44141935 4.2123871,7.59196774 L3.47951613,8.31893548 C3.32774194,8.46948387 3.32674194,8.71458065 3.47732258,8.8663871 L6.40574194,11.8185161 C6.55629032,11.9702903 6.8013871,11.9712903 6.95316129,11.8207097 L12.5205484,6.298 C12.6722903,6.14741935 12.6732581,5.90232258 12.5227097,5.75054839 L12.5227097,5.75054839 Z" id="check-circle" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        )}
        <span className="astm-alert-text">{alertMessage}</span>
      </div>
      <ul className="toster-inline-view">{props.fileNameList.length > 0 && props.fileNameList.map((item: string, index: number) => <li key={index}>{`${item}`}</li>)}</ul>
      <button type="button" className="close custom-close-btn" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
        <span aria-hidden="true">&times;</span>
      </button>
    </Alert>
  );
});

export default AlertDissmissable;
