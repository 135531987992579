import axios from 'axios';
import lodash from 'lodash';
import * as type from '../../action-type';
import * as commonActions from '../../common.actions';
import getApiUrl from '../../helpers/api-urls';
import * as utilCommon from '../../helpers/util-common';
import { cookieKeysEnum } from '../common.models';


export const authenticateByAzureADAction = (dispatch: any, callback: any) => {
	const url = getApiUrl('login', 'authenticateByAzureAD');
	dispatch(commonActions.setLoader(true))
	axios.get(url).then((response) => {
		dispatch(commonActions.setLoader(false))
		if (lodash.get(response.data, 'success')) {
			utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add details in LS.
			dispatch({ type: type.AZURE_LOGIN, payload: response });
			// Return true if get accessToken from OSL
			callback(true, lodash.get(response.data, 'message'));
		} else {
			utilCommon.setLoginDataToCacheManager(response, 'DELETE');
			callback(false, lodash.get(response.data, 'message'));
		}

	})
		.catch((err) => {
			const uniqueRequestId = utilCommon.getApiUniqueRequestId(url);
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '1012', uniqueRequestId));
		});
};

// Auth by Cookies token
export const authByCookiesTokenAction = (dispatch: any, inputDTO: any, callback: any) => {
	const url = getApiUrl('login', 'authCookiesToken');
	dispatch(commonActions.setLoader(true))
	axios.post(url, { source: inputDTO.appId }).then((response) => {
		dispatch(commonActions.setLoader(false))
		if (lodash.get(response.data, 'success')) {
			utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add details in LS.
			// Return true if get accessToken from OSL
			callback(true, lodash.get(response.data, 'message'));
		} else {
			utilCommon.setLoginDataToCacheManager(response, 'DELETE');
			callback(false, lodash.get(response.data, 'message'));
		}
	})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
		});
};
export const checkSessionInfo = (dispatch: any, callback: any) => {
	const url = getApiUrl('login', 'checkSessionInfo');
	dispatch(commonActions.setLoader(true))
	axios.get(url).then((response) => {
		dispatch(commonActions.setLoader(false))
		if (lodash.get(response.data, 'success')) {
			//utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add details in LS.
			// Return true if get accessToken from OSL
			callback(response.data, lodash.get(response.data, 'message'));
		} else {
			//utilCommon.setLoginDataToCacheManager(response, 'DELETE');
			callback(false, lodash.get(response.data, 'message'));
		}
	})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
		});
}

export default {
	authenticateByAzureADAction,
	authByCookiesTokenAction,
	checkSessionInfo
};
